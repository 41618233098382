import request from '@/utils/request'

/**
 * 创建site
 */
export const createNewSite = data => {
  return request({
    url: '/createNewSite',
    method: 'POST',
    data
  })
}

/**
 * 创建site PDlicense
 */
export const createNewSitePDLicense = (userId, swid) => {
  return request({
    url: `/approveUser/${userId}/${swid}`,
    method: 'get'
  })
}

/**
 * 创建site PDlicense
 */
export const createNewSiteSWLicense = (userId, swid) => {
  return request({
    url: `/createNewSiteSWLicense/${userId}/${swid}`,
    method: 'get'
  })
}

/**
 * 删除site
 */
export const deleteSite = params => {
  return request({
    url: '/deleteSite/',
    method: 'delete',
    params
  })
}
/**
 * 删除多个site
 */
export const deleteMoreSite = params => {
  return request({
    url: '/deleteSite/?' + params,
    method: 'delete'
  })
}

/**
 * 获取当前user site
 */
export const getCurLoginUserSites = userId => {
  return request({
    url: `/getCurLoginUserSites/${userId}`,
    method: 'get'
  })
}
/**
 * 获取当前user site
 */
export const getCurUserAllSites = (userId, data) => {
  return request({
    url: `/getCurUserAllSites/${userId}`,
    method: 'get',
    params: data
  })
}
/**
 * 获取当前user license for site
 */
export const getCurSitesAllLicenses = (data) => {
  return request({
    url: '/getCurSitesAllLicenses',
    method: 'get',
    params: data
  })
}
