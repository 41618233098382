<template>
  <div class="site">
    <div class="table-wrapper">
      <el-table
        :data="tableData"
        style="width: 100%"
        size="mini"
        fit
        header-cell-class-name="divTabelHeader"
        row-class-name="divTabelRow"
        cell-class-name="divTabelRowCell"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="sitename" :label="t('site_name')"> </el-table-column>
        <el-table-column prop="siteid" :label="t('site_Id')"> </el-table-column>
        <el-table-column prop="buildingtype" :label="t('building_type')"> </el-table-column>
        <el-table-column :label="t('operations')" width="260">
          <template #default="{ row }">
            <el-button
              type="primary"
              size="small"
              style="background: var(--bg-button-cancel); border: 2px solid var(--border-color)"
              @click="viewSiteDetail(row.siteid, row.sitename)"
            >
              {{ t('view_site_details') }}
            </el-button>

            <el-button type="primary" size="small" @click="deleteTheSite(row.siteid)">
              {{ t('delete') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[2, 5, 10, 20]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div class="footer">
      <el-button
        :disabled="multipleSelection.length === 0"
        type="primary"
        @click="deleteSelectSite"
      >
        {{ t('delete') }}</el-button
      >
      <el-button type="primary" @click="newSite"> + {{ t('create_new_site') }} </el-button>
    </div>
  </div>
  <!-- <AddNewSite v-model="addNewSiteDialogVisible" @getListData="getListData"></AddNewSite> -->
</template>

<script setup>
// 数据相关
import { onActivated, ref } from 'vue'
// import AddNewSite from './AddNewSite.vue'
import { useRouter } from 'vue-router'
import { deleteMoreSite, deleteSite, getCurUserAllSites } from '@/api/site'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const router = useRouter()
// const addNewSiteDialogVisible = ref(false)
const tableData = ref([])
tableData.value = []
const total = ref(0)
const page = ref(1)
const size = ref(10)
const store = useStore()
const multipleSelection = ref([])
// 获取数据的方法
const getListData = async () => {
  const result = await getCurUserAllSites(store.getters.userInfo.userId, {
    page: page.value - 1,
    size: size.value
  })
  tableData.value = result.content.list
  total.value = result.content.total
}

// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  size.value = currentSize
  getListData()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.value = currentPage
  getListData()
}
// 批量删除
const deleteSelectSite = () => {
  if (multipleSelection.value.length === 0) {
    return
  }
  ElMessageBox.confirm(t('delete_Sites_confirm'), t('delete_Sites'), {
    confirmButtonText: t('confirm'),
    cancelButtonText: t('cancel'),
    customClass: 'divConfirm',
    cancelButtonClass: 'divConcancelButton'
  })
    .then(async () => {
      const params = []
      multipleSelection.value.forEach(x => {
        params.push(`siteids=${x.siteid}`)
      })

      await deleteMoreSite(params.join('&'))
      ElMessage({
        message: t('delete') + t('success'),
        grouping: true,
        type: 'success',
        customClass: 'divSuccessElMessage'
      })
      await getListData()
    })
    .catch(() => {
      ElMessage({
        message: t('delete') + t('error'),
        grouping: true,
        type: 'error',
        customClass: 'divSuccessElMessage'
      })
    })
}
// 查看site详情
const viewSiteDetail = (siteId, sitename) => {
  router.push({ path: `/license/${siteId}`, query: { sitename: sitename } })
}

const deleteTheSite = siteId => {
  ElMessageBox.confirm(t('delete_Site_confirm'), t('delete_Site'), {
    confirmButtonText: t('confirm'),
    cancelButtonText: t('cancel'),
    customClass: 'divConfirm',
    cancelButtonClass: 'divConcancelButton'
  })
    .then(async () => {
      await deleteSite({ siteids: siteId })
      ElMessage({
        message: t('delete') + t('success'),
        grouping: true,
        type: 'success',
        customClass: 'divSuccessElMessage'
      })
      await getListData()
    })
    .catch(() => {
      ElMessage({
        message: t('delete') + t('error'),
        grouping: true,
        type: 'error',
        customClass: 'divSuccessElMessage'
      })
    })
}

const handleSelectionChange = async value => {
  multipleSelection.value = value
}

// 创建site
const newSite = async () => {
  router.push('/createNewSite')
  // addNewSiteDialogVisible.value = true
}
</script>

<style lang="scss" scoped>
.site {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .footer {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid var(--border-el-primary);
  }
  .table-wrapper {
    flex: 1;
  }
  ::v-deep .el-table {
    height: calc(100% - 65px);
  }
  .pagination {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
